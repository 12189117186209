/** @jsx jsx */
import React from 'react';
import { Link } from 'gatsby';
import { css, jsx } from '@emotion/core';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
import background from '../images/djangocon-2014.jpg';

const IndexPage = () => (
  <Layout>
    <SEO title="Developer/Author/Hobbyist/Ironman" />
    <div
      css={css`
        width: 100%;
        height: 100%;
        position: relative;
        background: url(${background}) no-repeat center center;
        background-size: cover;
      `}
    >
      <span
        css={css`
          position: absolute;
          bottom: 0;
          left: 5%;
          color: #f8f7f7;
          font-size: 0.8rem;
          padding: 0.25rem 0;
        `}
      >
        <a href="https://www.flickr.com/photos/caktusgroup/14943978800/in/set-72157646739732997/">
          Original image
        </a>{' '}
        by <a href="http://www.caktusgroup.com/">Caktus Group</a>. Used with
        permission.
      </span>
    </div>
  </Layout>
);

export default IndexPage;
